/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { lrc } from '../../utils/lr-client-instance';
import CurrentEventsController from '../../controllerViews/CurrentEventsController';
import EventMeta from './EventMeta';
import CategoryIcons from '../CategoryIcons';

import { isTrue } from '../../utils/utils';
import { SignUpButton } from '../EventActions/SignUpButton';
import { DEFAULT_DATE_FORMAT } from '../../constants/Constants';
import MapEW from '../MapEW';
import { CustomLink } from '../CustomLink';

const EventDetailsEW = ({ event, config, structureFields, showCurrentEvents, showBackLink, nextEvent, prevEvent }) => {
  const history = useHistory();
  const {
    title, startTime, endTime, description, allDay, attachments,
    uuid, links, subtitle, vocabularies, link_texts, geolocation,
    location, singleAppointments, small_image_url, AltTag, Bildnachweis, organizer, irregularAppointments
  } = event;

  const { portletSetupTitle_de_DE } = config;

  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);

  let dateString = `${startDate.format(DEFAULT_DATE_FORMAT)} bis ${endDate.format(DEFAULT_DATE_FORMAT)}`;
  if (allDay) {
    dateString = 'Ganztägig';
  } else if (startDate.isSame(endDate, 'day')) {
    dateString = startDate.format(DEFAULT_DATE_FORMAT);
  } else if (irregularAppointments) {
    dateString = `ab ${startDate.format(DEFAULT_DATE_FORMAT)}`;
  }

  const goBack = () => {
    const { eventCalendarViewPath } = config;
    if (eventCalendarViewPath) {
      window.open(`${window.location.origin}${config.eventCalendarViewPath}`);
    } else {
      history.replace({pathname: "/", search: sessionStorage.getItem("queryParams")});
    }
  };

  const categoryIds = [];
  vocabularies && vocabularies.forEach(v => {
    const { categories } = v;
    categories && categories.forEach(c => {
      categoryIds.push(c.id);
    });
  });

  return (
    <div className="event-details-container">
      <div className="event-details">
        <div className="data-head container-max-width">
          {
            showBackLink &&
            <h4 className="component-title">
		          <span className="header-back-to">
                <a
                  href="javascript:void(0);"
                  onClick={goBack}
                  className="taglib-icon"
                  title="Zurück zum Kalender"
                >
                  <span className="sr-only">Zur&uuml;ck zum Kalender</span>
                </a>
          		</span>
            </h4>
          }
        </div>

        <div className="container-max-width">
          <div className="taglib-page-iterator">
            <div className="pagination-bar">
              <div className="prev">
                {
                  prevEvent && prevEvent !== -1 &&
                  <CustomLink
                    to={`/-/kalender/event/${prevEvent}`}
                    ariaLabel="Zur vorhergehenden Veranstaltung"
                    title="Zur vorhergehenden Veranstaltung"
                    className="btn btn-simple"
                  >
                    <span className="icon-um icon-um--chevron-left no-border" aria-hidden="true"></span>
                    <span className="btn-title"> Vorherige Veranstaltung</span>
                  </CustomLink>
                }
              </div>
              <div className="next">
                {
                  nextEvent && nextEvent !== -1 &&
                  <CustomLink
                    to={`/-/kalender/event/${nextEvent}`}
                    ariaLabel="Zur nächsten Veranstaltung"
                    title="Zur nächsten Veranstaltung"
                    className="btn btn-simple"
                  >
                    <span className="btn-title"> N&auml;chste Veranstaltung </span>
                    <span className="icon-um icon-um--chevron-right no-border" aria-hidden="true"></span>
                  </CustomLink>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="data-content container-max-width">
          <div className="article">
            <div className="main-content">
              <p className="spitzmarke"> Veranstaltungskalender </p>
              <h1 className="title">{title}</h1>
            </div>
          </div>
          {
            isTrue(config.showImage) && small_image_url && small_image_url.length > 0 &&
            <div className="container-max-width">
              <div className="clearfix"/>
              <div className={'event-details__image'}>
                <img
                  src={lrc.getServerUrl() + small_image_url}
                  alt={AltTag}
                />
                {Bildnachweis && Bildnachweis.length > 0 &&
                  <button
                    className="xdbtn--link bildnachweis "
                  >
                    {Bildnachweis}
                  </button>
                }
              </div>
            </div>
          }

          <div className="container-max-width">
            <div className="content-width--ew">
              {subtitle && subtitle.length > 0 && <span className="xdh2">{subtitle}</span>}

              <div className="event-details__date">
                {`${dateString}${singleAppointments ? '' : ', ' + startDate.format('HH:mm') + ' bis ' + endDate.format('HH:mm') + ' Uhr'}`}
                <br/>
              </div>
            </div>
          </div>

          <div className="clearfix"/>

          <div className="container-max-width">
            <div className="event-details__shortinfo content-width--ew">
              <CategoryIcons vocabularies={vocabularies}/>
              <EventMeta
                event={event}
                config={config}
                structureFields={structureFields}
              />
            </div>
          </div>

          <div className="clearfix"/>

          <div className="event-details__inner-content">
            {
              isTrue(config.description) && description &&
              <div className="container-max-width">
                <div className="content-width--ew">
                  <p dangerouslySetInnerHTML={{ __html: description }}/>
                </div>
              </div>
            }
            {
              isTrue(config.links) && links && links.length > 0 &&
              <div className="container-max-width">
                <div className="content-width--ew">
                  <ul className="links">
                    {
                      links.map((item, i) => {
                        const target = (config.baseUrl && (item.indexOf(config.baseUrl) === 0)) ? '' : '_blank';
                        const iconClass = (config.baseUrl && (item.indexOf(config.baseUrl) === 0)) ? 'icon-um--chevron-right' : 'icon-um--external-link';

                        return (
                          <li className="item">
                            <a
                              href={item.startsWith('http') ? item : '//' + item}
                              target={target}
                              className="arrow-link"
                            >
                              <span className={`icon-um ${iconClass}`} aria-hidden="true"></span>
                              <span className="btn-title">{(link_texts && link_texts[i]) || item}</span>
                            </a>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              </div>
            }

            {
              isTrue(config.attachments) && attachments && attachments.length > 0 &&
              <div className="container-max-width">
                <div className="content-width--ew further-info">
                  <h2 className="pdf-title">Zum Herunterladen</h2>

                  <ul className="pdf-list">
                    {
                      attachments.map(item => {
                          const title = item.title + ' [' + item.extension.toUpperCase() + '; ' + Math.round((item.size / 1000000) * 100) / 100 + ' MB]';
                          return (
                            <li className="item" key={`download-${item.title}`}>
                              <a
                                href={`${window.eventManagerServerUrl}${item.download}`}
                                className="arrow-link"
                                title={title}
                              >
                                <span className="icon-um icon-um--download" aria-hidden="true"></span>
                                <span className="btn-title">{title}</span>
                              </a>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              </div>
            }
          </div>
        </div>

        {isTrue(config.organizer) && organizer &&
          <div className="container-max-width">
            <div className="content-width--ew">

              <div className="event-details__organizer">
                {organizer}
              </div>

              {
                isTrue(config.actorDescription) && event.actorDescription &&
                <div className="event-details__actorDescription">
                  <p>
                    {event.actorDescription}
                  </p>
                </div>
              }
            </div>
          </div>
        }

        <div className="container-max-width">
          <div className="event-details__shortinfo content-width--ew">
            {
              isTrue(config.actorWebsite) && event.actorWebsite &&
              <a
                href={event.actorWebsite.startsWith('http') ? event.actorWebsite : '//' + event.actorWebsite}
                target="_blank"
                className="arrow-link"
              >
                <span className="icon-um icon-um--external-link" aria-hidden="true"></span>
                <span className="btn-title">{event.actorWebsite}</span>

              </a>
            }
            {
              ((isTrue(config.actorPhone) && event.actorPhone) || (isTrue(config.actorEmail) && event.actorEmail)) &&
              <table>
                <tbody>
                {
                  isTrue(config.actorEmail) && event.actorEmail &&
                  <tr>
                    <th valign="top" colspan="2">
                      <a href={'mailto:' + event.actorEmail} className="arrow-link multirow-link">
                        <span className="icon-um icon-um--e-mail" aria-hidden="true"></span>
                        <div className="btn-title-wrapper">
                          <span className="btn-title">{event.actorEmail}</span>
                        </div>
                      </a>
                    </th>
                  </tr>
                }
                {
                  isTrue(config.actorPhone) && event.actorPhone &&
                  <tr>
                    <th valign="top" colSpan="2">
                      <a href={'tel:' + event.actorPhone} className="arrow-link multirow-link">
                        <span className="icon-um icon-um--phone" aria-hidden="true"></span>
                        <div className="btn-title-wrapper">
                          <span className="btn-title">{event.actorPhone}</span>
                        </div>
                      </a>
                    </th>
                  </tr>
                }
                </tbody>
              </table>
            }
          </div>
        </div>

        <div>

          {
            geolocation && <MapEW event={event}/>
          }
        </div>

        <SignUpButton event={event}/>
      </div>


      {
        showCurrentEvents &&
        <div className="container-max-width event-details__current-events">
          <CurrentEventsController
            isEventDetail={true}
            uuid={uuid}
            categoryIds={categoryIds}
            namespace="event_details"
          />
        </div>
      }

    </div>
  );
};

export default EventDetailsEW;
