import React from 'react';
import { useSearchContext } from '../context/SearchContext';
import dayjs from 'dayjs';
import { DateParam, NumberParam, QueryParams, withDefault } from 'use-query-params';
import FilterWrapperBITBW from './FilterWrapperBITBW';

const DateRangeValueSelectionDefault = () => {
  const { query, setQuery, search } = useSearchContext();
  const { startTime, endTime, dateRangeVal, includeOfferings } = query;

  const queryConfig = {
    startTime: withDefault(DateParam, new Date(startTime)),
    endTime: withDefault(DateParam, new Date(endTime)),
    dateRange: withDefault(NumberParam, dateRangeVal)
  };

  const onDateRangeValChange = (e) => {
    const value = e.target.value;
    let startTimeEdit = startTime;
    let endTimeEdit = endTime;
    let q = {};
    if (value === "-1") {
      startTimeEdit = dayjs(startTime).toDate();
      endTimeEdit = dayjs(endTime).toDate();

      q = {
        ...query,
        dateRangeVal: value
      };
    } else {
      startTimeEdit = dayjs().startOf('day');
      endTimeEdit = dayjs().add(value, 'days');

      q = {
        ...query,
        dateRangeVal: value,
        startTime: startTimeEdit.valueOf(),
        endTime: endTimeEdit.valueOf()
      };

      startTimeEdit = startTimeEdit.toDate();
      endTimeEdit = endTimeEdit.toDate();
    }
    setQuery(q);
    search(q);
    return { startTime: startTimeEdit, endTime: endTimeEdit, dateRange: value };
  };

  return (
    <QueryParams config={queryConfig}>
      {({query, setQuery}) => {
        const {
          startTime, endTime,
          dateRange
        } = query;

        return (
          <select
            value={dateRange}
            onChange={e => setQuery(onDateRangeValChange(e))}
            disabled={includeOfferings}
            id="event-time-range"
          >
            <option value="0">Heute</option>
            <option value="7">In den nächsten 7 Tagen</option>
            <option value="30">In den nächsten 30 Tagen</option>
            <option value="365">Innerhalb eines Jahres</option>
            <option value="1460">Innerhalb der nächsten 4 Jahre</option>
            <option value="-1">Individueller Zeitraum</option>
          </select>
        );
      }}
    </QueryParams>
  );
};

export default DateRangeValueSelectionDefault;
