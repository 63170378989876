/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017input-wrapper
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import DatePicker from './DatePicker';
import dayjs from 'dayjs';
import { useSearchContext } from '../context/SearchContext';
import { QueryParams, withDefault, DateParam, NumberParam } from 'use-query-params';
import DateRangeValueSelection from './DateRangeValueSelection';

const EventCalendarDateRangePicker = () => {
  const { query, setQuery, search } = useSearchContext();
  const { startTime, endTime, dateRangeVal, includeOfferings } = query;

  const queryConfig = {
    startTime: withDefault(DateParam, new Date(startTime)),
    endTime: withDefault(DateParam, new Date(endTime)),
    dateRange: withDefault(NumberParam, dateRangeVal)
  };

  const onBeginDateChange = (date) => {
    const dateObj = new Date(date);
    dateObj.setHours(0, 0, 0, 0);
    date = dateObj.getTime();
    const q = {
      ...query,
      startTime: date.valueOf(),
      dateRangeVal: -1
    };
    setQuery(q);
    search(q);
    return dateObj;
  };

  const onEndDateChange = (date) => {
    const dateObj = new Date(date);
    dateObj.setHours(23, 59, 59, 999);
    date = dateObj.getTime();
    const q = {
      ...query,
      endTime: date.valueOf(),
      dateRangeVal: -1
    };
    setQuery(q);
    search(q);
    return dateObj;
  };

  return (
    <QueryParams config={queryConfig}>
      {({query, setQuery}) => {
        const { startTime, endTime,
          dateRange } = query;

        return (
          <div className="date-range-picker">
            <div className={`date-range-picker-p date-range-select ${includeOfferings ? 'xddisabled' : ''}`}>
              <label
                className="xdlabel"
                htmlFor="event-time-range"
              >
                Zeitraum
              </label>
              <DateRangeValueSelection />
            </div>

            <div className={`date-range-picker-p ${includeOfferings ? 'xddisabled' : ''}`}>
              <label
                className="xdlabel event-start-date"
                htmlFor="event-start-date"
                aria-label="Startdatum der Suche"
              >
                Von
              </label>
              <DatePicker
                id="event-start-date"
                value={startTime}
                onChange={date => setQuery({ startTime: onBeginDateChange(date) })}
                disabled={includeOfferings}/>
            </div>
            <div className={`date-range-picker-p ${includeOfferings ? 'xddisabled' : ''}`}>
              <label
                className="xdlabel event-end-date"
                htmlFor="event-end-date"
                aria-label="Enddatum der Suche"
              >
                Bis
              </label>
              <DatePicker
                id="event-end-date"
                value={endTime}
                onChange={date => setQuery({ endTime: onEndDateChange(date) })}
                disabled={includeOfferings}
              />
            </div>
          </div>
        );
      }}
    </QueryParams>
  );
};

export default EventCalendarDateRangePicker;
